import commonFetch from "./fetch";

type AuthInitResponse = {
  access_token: string;
};

const fetchAuthInit = (id : string): Promise<AuthInitResponse> => {
  return commonFetch({
    url: 'auth/init',
    method: 'POST',
    fetchOptions: {
      body: JSON.stringify({
        id,
      }),
    },
  });
}

const fetchPublicInfo = (
  key: string,
  accessToken: string, 
  ): Promise<{ services: any }> => {
  return commonFetch({
    url: `client/public/${key}`,
    headers: {
      'Authorization': `Bearer ${accessToken}`,
      'alloy-journey-application-sync': 'true'
    },
    method: 'GET',
  });
}

type JourneyApplicationResponse = {
  journey_application_token: string;
};

const generateJourneyApplication = (
  journeyToken: string, 
  accessToken: string, 
  journeyData: any,
  production: boolean = false,
  ): Promise<JourneyApplicationResponse> => {
  return commonFetch({
    url: `v1/journeys/${journeyToken}/applications`,
    headers: {
      'Authorization': `Bearer ${accessToken}`,
      'alloy-journey-application-sync': 'true',
      'alloy-sandbox': production ? 'false' : 'true',
    },
    method: 'POST',
    fetchOptions: {
      body: JSON.stringify({...journeyData})
    }
  });
}

const generateEvaluation = (
  accessToken: string, 
  evaluationData: any,
  entityToken?: string, 
  externalEntityId?: string,
  production: boolean = false,
) => {
  return commonFetch({
    url: 'evaluations',
    headers: {
      'Authorization': `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
      ...(entityToken && { 'Alloy-Entity-Token': entityToken }),
      ...(externalEntityId && {
        'Alloy-External-Entity-ID': externalEntityId,
      }),
      'alloy-sandbox': production ? 'false' : 'true',
    },
    method: 'POST',
    fetchOptions: {
      body: JSON.stringify({...evaluationData})
    }
  });
}

type EntityResponse = {
  entity_token: string;
};

export  {
  fetchAuthInit,
  fetchPublicInfo,
  generateJourneyApplication,
  generateEvaluation,
}

