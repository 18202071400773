const getEmbeddedDataSources = ({ socureDeviceId, isNeuroIdEnabled, neuroIdSiteId, iovationBlackboxId }) => {
  const sources: any = {};
  if (socureDeviceId) sources.socure_session_id = socureDeviceId;
  if (isNeuroIdEnabled && neuroIdSiteId) {
    const identityId = sessionStorage.getItem('identityId');
    sources.neuro_user_id = identityId;
    sources.site_id = neuroIdSiteId
  } 
  if (iovationBlackboxId) {
    sources.iovation_blackbox = iovationBlackboxId;
  }
  return sources;
}

export default getEmbeddedDataSources;