declare global {
  interface Window {
    IGLOO: any;
  }
}

export default async () => {
  window.IGLOO = window.IGLOO || {
    loader: {
      uri_hook: '/iojs/', // route that contains the proxy to Iovation on the server side
      version: 'general5', // version of the javascript to use
      subkey: '5FExse+oA1134BhiwCF2EeQ1TfisPJGha4CpVG2nd7E=',
    }
  };

const delay = (milliseconds: number) =>
  new Promise((resolve) => setTimeout(resolve, milliseconds));


  const thirdPartyScript = document.createElement('script');
  thirdPartyScript.src = 'https://ci-mpsnare.iovation.com/snare.js';
  thirdPartyScript.type = 'text/javascript';
  thirdPartyScript.async = true;
  document.head.appendChild(thirdPartyScript)

  const thirdPartyId = await new Promise<string>(resolve => {

    const getThirdPartyId = async() => {
      thirdPartyScript.removeEventListener('load', getThirdPartyId)
      try {
        // @ts-ignore
        const id = typeof(ioGetBlackbox) === 'function' ? ioGetBlackbox()?.blackbox : null;
        resolve(id);
      } catch (err) {
        resolve('');
      }
    }

    thirdPartyScript.addEventListener('load', getThirdPartyId);
  })

  if (!thirdPartyId) {
    // Appending this script throws an error in the console which clients do not like
    // so don't execute this block of code unless necessary
    const script = document.createElement('script');
    script.src = 'https://scripts.alloy.com/iovation.js';
    script.type = 'text/javascript';
    script.async = true;
    script.setAttribute('data-status', 'loading');
    document.head.appendChild(script)

    const firstPartyId = await new Promise<string>(resolve => {
          const getFirstPartyId = async() => {
            script.removeEventListener('load', getFirstPartyId)
            // TODO: refactor to remove delay
            await delay(500)
            try {
             const id = window.IGLOO.getBlackbox()?.blackbox
             resolve(id);
            } catch (err) {
              resolve('');
            }
          }
          script.addEventListener('load', getFirstPartyId);
    })
    return firstPartyId;
  }

  return thirdPartyId;
}
